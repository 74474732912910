<template>
  <div>
    <b-row class="mb-4">
      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col>
              <h4>PPE Request Search</h4>
            </b-col>
            <b-col class="text-right">
              <b-button variant="primary" size="sm" squared @click="createNewPpeFor()">Create New</b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
                <label>Requested For</label>
                <b-input-group>
                    <b-form-input v-model="search.createdFor.name" :disabled="true"></b-form-input>
                    <b-input-group-append>
                        <b-button size="sm"  text="Button" variant="secondary" @click="openAddUserModal()">Search</b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
            <b-col cols="4">
              <label>Date Requested From</label>
              <b-form-datepicker v-model="search.dateCreatedFrom"></b-form-datepicker>
            </b-col>
            <b-col cols="4">
              <label>Date Requested To</label>
              <b-form-datepicker v-model="search.dateCreatedTo"></b-form-datepicker>
            </b-col>
          </b-row>

          <hr class="mx-3" />
          <b-row>
            <b-col cols="12" class="text-right">
              <b-button variant="red" squared class="mr-2" @click="clearFilter()">Clear</b-button>
              <b-button variant="primary" squared @click="searchResults()">Search</b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col>
              <b-table striped hover :items="tableData.dataSource" :fields="tableData.tableColumns"
                       :busy="tableData.isLoading" @row-clicked="openCard"
                        :per-page="tableData.perPage"
                        :current-page="tableData.currentPage" sort-icon-left>
                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                  </div>
                </template>

                <template #cell(requestDate)="row">
                  <b-row align-v="center">
                    <span class="mr-auto">{{row.item.requestDate | dateTimeFilter}}</span>
                  </b-row>
                </template>

                <template #cell(dateReceived)="row">
                  <b-row align-v="center">
                    <span class="mr-auto">{{row.item.dateReceived | dateTimeFilter}}</span>
                  </b-row>
                </template>

                <template #cell(status)="row">
                    <b-row align-v="center">
                        <b-badge :variant="getVariantForStatus(row.item.status)">{{row.item.status}}</b-badge>
                    </b-row>
                </template>

                <template #cell(actions)="row">
                  <b-row align-v="center" align-h="end">
                    <b-button variant="primary" squared @click="openCard(row.item)" size="sm">View</b-button>
                  </b-row>
                </template>

              </b-table>
            </b-col>
          </b-row>
          <b-row align-h="center" >
            <b-pagination
                v-model="tableData.currentPage"
                :total-rows="rows"
                :per-page="tableData.perPage"
            ></b-pagination>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    
        <b-modal id="search-user-modal" size="xl" hide-footer no-header @close="closeSearchUserModal">
            
            <b-row>
                <b-col cols="4">
                    <label>Name</label>
                    <b-form-input v-model="user.name"></b-form-input>
                </b-col>
                <b-col cols="4">
                    <label>Surname</label>
                    <b-form-input v-model="user.surname"></b-form-input>
                </b-col>
                <b-col cols="4">
                    <label>Email</label>
                    <b-form-input v-model="user.email"></b-form-input>
                </b-col>
            </b-row>
            <hr class="mx-3">
            <b-row>
                <b-col class="text-right">
                    <b-button variant="red" class="ml-2" squared @click="clearUsersFilter" >Clear</b-button>
                    <b-button variant="primary" squared @click="doSearchUsers" class="ml-2">Search</b-button>
                </b-col>
            </b-row>
            
            <b-row>
                <b-col class="12">
                    <b-table striped hover 
                            :items="tableDataUsers.dataSource" 
                            :fields="tableDataUsers.tableColumns"
                            :busy="tableDataUsers.isLoading" 
                            @row-clicked="chooseUser"
                            :per-page="tableDataUsers.resultsPerPage"
                            id="userSearch"
                            :current-page="tableDataUsers.currentPage" sort-icon-left>

                        <template #table-busy>
                            <div class="text-center my-2">
                                <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                            </div>
                        </template>

                        <template #cell(actions)="row">
                            <b-row align-v="center" align-h="end">
                                <b-button @click="chooseUser(row.item)" size="sm" class="btn-icon">
                                <b-icon-chevron-right></b-icon-chevron-right>
                                </b-button>
                            </b-row>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
          
            <b-row align-h="center" >
                <b-pagination
                    v-model="tableDataUsers.currentPage"
                    :total-rows="userRows"
                    :per-page="tableDataUsers.resultsPerPage"
                    aria-controls="userSearch"
                ></b-pagination>
            </b-row>
            <b-row>
                <b-col class="text-right">
                    <b-button variant="red" class="mr-2" squared @click="closeSearchUserModal">Cancel</b-button>
                </b-col>
            </b-row>
        </b-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
  data: () => ({
    state: 'initiliazing',
    newQuoteTicket: null,
    checklistTypes: [],
    statusList: [],
    search:{
      description: null,
      dateCreatedFrom: null,
      dateCreatedTo: null,
      createdFor: {name: null}
    },
        user: {
            name: null,
            surname: null,
            email: null,
            roleID: 0
        },
        tableDataUsers: {
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                label: 'Name',
                key: 'name',
                sortable: true,
                tdClass: '',
                },
                {
                label: 'Surname',
                key: 'surname',
                sortable: true,
                tdClass: '',
                },
                {
                label: 'Email',
                key: 'email',
                sortable: true,
                tdClass: '',
                },
                {
                label: 'Contact Number',
                key: 'contactNumber',
                sortable: true,
                tdClass: '',
                },
                {
                label: '',
                key: 'actions',
                sortable: false,
                tdClass: ''
                }
            ]
        },
    tableData: {
      totalRecords: 10,
      perPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [

        {
          label: 'Requested By',
          key: 'createdFor',
          sortable: true,
          tdClass: ''
        },
        {
          label: 'Date Requested',
          key: 'requestDate',
          sortable: true,
          tdClass: ''
        },

        {
          label: 'Date Received',
          key: 'dateReceived',
          sortable: true,
          tdClass: ''
        },
        {
          label: 'Status',
          key: 'status',
          sortable: true,
          tdClass: ''
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: ''
        }
      ]
    }
  }),
  created(){
    this.setBreadcrumb([
      {
        text: 'Open PPE Requests'
      },
      {
        text: 'Search',
        active: true
      }
    ])

    this.searchResults()
  },
  methods:{
    ...mapActions(['searchPpeRequests', 'searchUsersRequest']),
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
    chooseUser(rowItem){
      this.state = 'loading'
      //console.log(rowItem)
      this.search.createdFor = rowItem
      this.state = 'show'
      this.closeSearchUserModal()
    },
    getVariantForStatus(rowItem){
      let itemStatus = null
      switch (rowItem) {
          case 'Received':
              itemStatus = 'success'
              break;
          case 'New':
              itemStatus = 'primary'
              break;
          case 'Denied':
              itemStatus = 'error'
              break;
          case 'Acquiring Items':
              itemStatus = 'info'
              break;
          case 'Ready For Pickup':
              itemStatus = 'warning'
              break;
      }

      return itemStatus
    },
    doSearchUsers(){
      this.tableDataUsers.isLoading = true

      let request = []
      
      if (this.user.name != null) {
          request.push({'key': 'name', 'value': this.user.name})
      }
      if (this.user.surname != null) {
          request.push({'key': 'surname', 'value': this.user.surname})
      }
      if (this.user.email != null) {
          request.push({'key': 'email', 'value': this.user.email})
      }
      if (this.user.roleId != null) {
          request.push({'key': 'fkroleId', 'value': this.user.roleId})
      }
      
      this.$store.commit('setUserSearchRequest', request)
      
      this.searchUsersRequest()
      .then((request) => {
        this.tableDataUsers.dataSource = request.data
        this.tableDataUsers.isLoading = false
        this.state  = 'initialize'
      })
      .catch( () => {
        this.tableDataUsers.isLoading = false
      })
    },
    clearUsersFilter(){
      this.user = {
        name: null,
        surname: null,
        email: null
      }
    },
    openAddUserModal(){
        this.$bvModal.show('search-user-modal')
        this.user.roleId = null
        this.doSearchUsers()
    },
    closeSearchUserModal(){
        this.$bvModal.hide('search-user-modal')
    },
    createNewPpeFor(){
      this.$router.push({path: '/ppe/create/for'})
    },
    openCard(rowItem){
      this.$store.commit('setSelectedPpeRequest', rowItem)
      this.$router.push({path: '/ppe/current/view'})
    },
    clearFilter(){
      this.search = {
        description: null,
        dateCreatedFrom: null,
        dateCreatedTo: null,
        createdFor: {name: null}
      }
    },
    searchResults(){
      this.tableData.isLoading = true

      let request = []
      if(this.search.dateCreatedTo !== null){
        request.push({"key":"createdDateTo","value": this.search.dateCreatedTo})
      }
      if(this.search.dateCreatedFrom !== null){
        request.push({"key":"createdDateFrom","value": this.search.dateCreatedFrom})
      }
      if(this.search.createdFor.name !== null){
        request.push({"key":"fkForUserId","value": this.search.createdFor.userId})
      }

      this.$store.commit('setSearchPpeRequestsRequest', request)
      this.searchPpeRequests()
          .then((res) => {
            this.tableData.dataSource = res.data
            this.tableData.isLoading = false
          })
          .catch(() => {
            this.tableData.isLoading = false
          })
    }
  },
  computed:{
    
        rows()
        {
            return this.tableData.dataSource.length
        },
    userRows() {
        return this.tableDataUsers.dataSource.length
    }
  }
}
</script>
